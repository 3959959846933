import React from "react";
import { Container } from "../../../styles/UI";

import paralax from "../../../images/paralax.svg";

import * as S from "./style";
import ProductCard from "../../../components/ProductCard";

export default function ProdutosHome(props) {
  return (
    <S.Produtos $white={props.white} $border={props.border}>
      <Container $clip>
        {/* {props.paralax && <img src={paralax} className="paralax" alt="" />} */}
        <h2>
          Conheça as
          <br />
          <span>Soluções da Taxcel</span>
        </h2>
        <div className="cards">
          <ProductCard product="taxsheets" />
          <ProductCard product="taxdashs" />
        </div>
      </Container>
    </S.Produtos>
  );
}
